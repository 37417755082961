import { AppMenuDto, CrudActionDto, CrudAction } from './app.model';
import { ViewWeatherComponent } from './pages/port/view-weather/view-weather.component';
import { ViewLocationComponent } from './pages/vessel/view-location/view-location.component';
import { eIdentityRouteNames } from '@abp/ng.identity/config';
import { CustomColumn } from './xui/x-page/x-pg-model/x-pg.model';
// defaultModule : 'x/pg'

export const masterMenus = [
  // sample
  {
    path: 'sample',
    module: 'pages',
    invisible: true,
    requiredPolicy: 'Masters.Countries',
    crudAction: {
      list: 'pages/sample',
      read: 'pages/sample',
      create: 'pages/sample',
      update: 'pages/sample',
    } as CrudActionDto,
  },

  // TemplateDownload
  {
    path: 'templatedownload',
    module: 'pages',
    invisible: true,
  },
  // WeatherReport
  {
    path: 'weatherreport',
    module: 'pages',
    invisible: true,
  },
  // InterfaceType
  {
    path: 'interfacetype',
    iconClass: 'deployment-unit',
    requiredPolicy: 'Masters.InterfaceTypes',
  },
  // TypeOfCallGroups
  {
    path: 'typeofcallgroup',
    iconClass: 'deployment-unit',
    requiredPolicy: 'Masters.TypeOfCallGroups',
  },
  // CustomerTypeMaster
  {
    path: 'customertypemaster',
    iconClass: 'deployment-unit',
    requiredPolicy: 'Masters.CustomerTypeMasters',
  },
  // Country
  {
    path: 'country',
    iconClass: 'flag',
    requiredPolicy: 'Masters.Countries',
    features: { picture: true },
  },
  // Currency
  {
    path: 'currency',
    iconClass: 'dollar',
    requiredPolicy: 'Masters.Currencies',
  },
  // Office
  {
    path: 'office',
    lookupFieldName: 'code',
    lookupFieldCode: 'name',
    features: { attachments: true, comments: true },
    columns: [
      'code',
      'name',
      'city',
      'currencyName',
      'keyContactPerson',
      'telePhone',
      'mobile',
      'active',
    ],
    customColumns: [
      { prop: 'logoUrl', name: 'logoUrl', type: 'file' },
      { prop: 'agencyAppLayout', name: 'agencyAppLayout', type: 'string' }
    ],
    iconClass: 'bank',
    children: [
      {
        columns: ['name', 'isDomestic', 'isDefault', 'active'],
        path: 'bank',
        requiredPolicy: 'Masters.Offices',
      },
    ],
    requiredPolicy: 'Masters.Offices',
  },
  // Port
  {
    path: 'port',
    iconClass: 'bank',
    features: { attachments: true },
    children: [
      { path: 'portlocation', requiredPolicy: 'Masters.Ports', features: { attachments: true } },
      { path: 'portzone', requiredPolicy: 'Masters.PortZones' },
      { path: 'portpdacolumn', requiredPolicy: 'Masters.Ports' },
    ],
    tabs: [{ path: 'view-weather', component: ViewWeatherComponent }],
    requiredPolicy: 'Masters.Ports',
  },
  // Port and Office Mapping
  {
    path: 'portofficemaster',
    lookupFieldName: 'officeName',
    lookupFieldCode: 'portName',
    iconClass: 'one-to-one',
    requiredPolicy: 'AgencyPlatform.PortOfficeMasters',
    invisible: true,
  },
  // MainGroup
  {
    path: 'maingroup',
    iconClass: 'partition',
    children: [
      {
        iconClass: 'group',
        path: 'subgroup',
        columns: [
          'code',
          'name',
          'addressLine1',
          'addressLine2',
          'city',
          'telePhone',
          'keyContactPerson',
          'active',
        ],
        requiredPolicy: 'Masters.Customers',
        children: [{ path: 'contact', iconClass: 'contacts' }],
        autoGenerateField: 'code',
      },
      { path: 'contact', iconClass: 'contacts' },
    ],
    requiredPolicy: 'Masters.Customers',
    features: { attachments: true, comments: true, picture: true },
  },
  // Service
  {
    path: 'typeofcall',
    iconClass: 'appstore',
    requiredPolicy: 'Masters.TypeOfCalls',
    autoGenerateField: 'code',
  },
  // Charge Item Section
  {
    path: 'chargeItemSection',
    iconClass: 'group',
    requiredPolicy: 'Masters.ChargeItemSections',
    autoGenerateField: 'code',
  },
  // chargeitem
  {
    path: 'chargeitem',
    iconClass: 'ci',
    children: [
      {
        path: 'chargeitemaxaccount',
        requiredPolicy: 'Masters.ChargeItems',
      },
    ],
    requiredPolicy: 'Masters.ChargeItems',
    autoGenerateField: 'code',
  },

  // TaskItems
  {
    path: 'taskitem',
    iconClass: 'unordered-list',
    requiredPolicy: 'Masters.TaskItems',
    autoGenerateField: 'code',
    module: 'pages',
    crudAction: { list: 'pages/taskitem' },
  },

  // AX Account Master
  {
    path: 'refaccountmaster',
    iconClass: 'book',
    children: [
      {
        path: 'chargeitemaccountmapping',
        requiredPolicy: 'AgencyPlatform.ChargeItemAccountMappings',
      },
    ],
    requiredPolicy: 'AgencyPlatform.RefAccountMasters',
    invisible: true,
  },
  // Customer
  {
    path: 'customer',
    iconClass: 'team',
    features: { attachments: true, comments: true, picture: true },
    requiredPolicy: 'Masters.Customers',
    crudAction: {
      list: 'pages/customer',
      read: 'pages/customer',
      create: 'pages/customer',
    } as CrudActionDto,
    children: [
      {
        iconClass: 'group',
        path: 'subgroup',
        name: 'Customers',
        parentPropRef: 'subgroupid',
        requiredPolicy: 'Masters.Customers',
        children: [{ path: 'contact', iconClass: 'contacts' }],
      },
    ],
    invisible: true,
    autoGenerateField: 'code',
  },
  // Designation
  {
    path: 'designation',
    requiredPolicy: 'Masters.Designations',
  },
  // EventItem
  {
    path: 'eventitem',
    iconClass: 'calendar',
    requiredPolicy: 'Masters.EventItems',
  },
  // CargoType
  {
    path: 'cargotype',
    iconClass: 'build',
    requiredPolicy: 'Masters.CargoTypes',
  },
  // BunkerType
  {
    path: 'bunkertype',
    iconClass: 'pic-right',
    requiredPolicy: 'Masters.BunkerTypes',
  },
  // EmailType
  {
    path: 'emailtype',
    iconClass: 'mail',
    requiredPolicy: 'Masters.EmailTypes',
  },
  // Vessel
  {
    path: 'vessel',
    iconClass: 'crown',
    features: { attachments: true, comments: true, picture: true },
    columns: ['name', 'imoNumber', 'callSign', 'mmsi', 'vesselTypeName', 'active'],
    tabs: [
      { path: 'view-location', component: ViewLocationComponent, iconClass: 'aim' },
      // { path: 'vessel-contacts', component: VesselContactsComponent, iconClass: 'contacts' },
    ],
    children: [
      {
        // columns: ['name', 'isDomestic', 'isDefault', 'active'],
        path: 'vesselcontact',
        requiredPolicy: 'Masters.Vessels',
        iconClass: 'contacts',
      },
    ],
    requiredPolicy: 'Masters.Vessels',
    invisible: true,
    crudAction: { list: 'pages/vessel', create: 'pages/vessel', update: 'pages/vessel' },
  },
  // Vessel Type
  {
    path: 'vesseltype',
    iconClass: 'crown',
    requiredPolicy: 'Masters.VesselTypes',
  },
  // Email
  {
    path: 'contact',
    requiredPolicy: 'AgencyPlatform.Contacts',
    invisible: true,
  },
  // customertype
  {
    path: 'customertype',
    // requiredPolicy: 'AgencyPlatform.CustomerTypes',
    crudAction: {
      create: CrudAction.hidden,
    },
  },

  {
    path: 'unitofmeasure',
    iconClass: 'mail',
    requiredPolicy: 'Masters.UnitofMeasures',
  },

  // Users
  {
    path: 'userprofile',
    invisible: true,
    crudAction: {
      read: 'admin/users',
      create: 'admin/users',
    },
  },
  {
    path: 'identityuser',
  },
  {
    path: 'appuser',
    requiredPolicy: 'AbpIdentity.Users',
    invisible: true,
    crudAction: {
      list: 'admin/internalusers',
    },
  },

  // Vendor
  {
    path: 'vendor',
    iconClass: 'deployment-unit',
    features: { attachments: true, comments: true, picture: true },
    links: [{ path: 'invoice', requiredPolicy: 'Transactions.Invoices', iconClass: 'file-text' }],
    children: [
      {
        path: 'vendorcontact',
        requiredPolicy: 'Masters.Vendors',
        iconClass: 'contacts',
      },
    ],
    requiredPolicy: 'Masters.Vendors',
    invisible: true,
    autoGenerateField: 'code',
    crudAction: {
      list: 'pages/vendor',
      read: 'pages/vendor',
    },
  },

  // Invoice
  {
    path: 'invoice',
    requiredPolicy: 'Masters.Invoices',
    lookupFieldName: 'invoiceNo',
    lookupFieldCode: 'details',
    invisible: true,
    crudAction: {
      read: 'vendor',
      create: CrudAction.hidden,
      update: CrudAction.hidden,
      delete: CrudAction.hidden,
    },
  },

  // vendorType
  {
    path: 'vendortype',
    iconClass: 'deployment-unit',
    requiredPolicy: 'Masters.VendorTypes',
    // invisible: true,
  },

  // Templates
  {
    path: 'template',
  },

  // Static Templates
  {
    path: 'statictemplate',
    module: 'pages',
    iconClass: 'file-protect',
    requiredPolicy: 'Masters.Templates',
    crudAction: {
      list: 'pages/statictemplate',
      read: 'pages/statictemplate',
      update: 'pages/statictemplate',
      delete: CrudAction.hidden,
    },
  },

  // Sop Input
  {
    path: 'sopinput',
    iconClass: 'border-outer',
    lookupFieldName: 'title',
    requiredPolicy: 'Masters.SopInputs',
    crudAction: {
      create: 'pages/sopinput',
      update: 'pages/sopinput',
    },
  },

  // SOP Templator
  {
    path: 'sopreport',
    module: 'pages',
    iconClass: 'file-sync',
    lookupFieldName: 'name',
    requiredPolicy: 'Masters.SopReports',
    crudAction: {
      list: 'pages/sopreport',
      read: 'pages/sopreport',
      create: 'pages/sopreport',
      update: 'pages/sopreport',
    },
  },

  // userGroup
  {
    path: 'usergroup',
    iconClass: 'deployment-unit',
    requiredPolicy: 'Masters.UserGroups',
    invisible: true,
  },
  // quicklink
  {
    path: 'quicklink',
    requiredPolicy: 'Common.QuickLinks',
  },

  // entitycodeseq
  {
    path: 'entitycodeseq',
    name: 'EntityCodeSeq',
    invisible: true,
  },

  // Email
  {
    path: 'email',
    iconClass: 'mail',
  },
  {
    path: 'company',
    requiredPolicy: 'Common.QuickLinks'
  },
  {
    path: 'tenantinfo',
    module: 'pages',
    requiredPolicy: 'Common.QuickLinks',
    crudAction: {
      list: 'pages/tenantinfo',
      read: 'pages/tenantinfo',
      create: 'pages/tenantinfo',
      update: 'pages/tenantinfo',
    },
    features: {},
    tabs: [],
    customColumns: [
      { name: "logoUrl", prop: "logoUrl", type: 'string' },
      { name: "hasMobile", prop: "hasMobile", type: 'boolean' },
      { name: "disableMobile", prop: "disableMobile", type: 'boolean' },
      { name: "loginContent", prop: "loginContent", type: 'string' },
      { name: "copyright", prop: "copyright", type: "string" },
      { name: "aboutLink", prop: "aboutLink", type: "string" },
      { name: "contactLink", prop: "contactLink", type: "string" },
      { name: "inquiryLink", prop: "inquiryLink", type: "string" },
      { name: "logoUrl2", prop: "logoUrl2", type: "string" },
      { name: "disableDashboard", prop: "disableDashboard", type: "boolean" },
      { name: "appName", prop: "appName", type: "string" }
    ]
  }
] as AppMenuDto[];

export const adminMenu = [
  {
    path: 'roles',
    iconClass: 'apartment',
    name: eIdentityRouteNames.Roles,
    requiredPolicy: 'AbpIdentity.Roles',
  },
  {
    path: 'usergroup',
    iconClass: 'deployment-unit',
    name: 'User Group',
    module: 'x/pg',
    requiredPolicy: 'Masters.UserGroups',
  },
  {
    path: 'users',
    iconClass: 'user',
    requiredPolicy: 'AbpIdentity.Users',
  },
  {
    path: 'externalusers',
    iconClass: 'user',
    name: 'External Users',
    requiredPolicy: 'AbpIdentity.Users',
  }
];
