import type { CreateVesselDto, PS07Response, UpdateVesselDto, VD02Response, VesselDto, VesselListDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { DataRequestDto, EntitySchemaDto, IdNameDto, PageRequestDto } from '../models';

@Injectable({
  providedIn: 'root',
})
export class VesselService {
  apiName = 'Default';

  create = (input: CreateVesselDto) =>
    this.restService.request<any, VesselDto>({
      method: 'POST',
      url: `/api/app/vessel`,
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/vessel/${id}`,
    },
    { apiName: this.apiName });

  fetchVesselParticularsById = (id: string) =>
    this.restService.request<any, VD02Response>({
      method: 'POST',
      url: `/api/app/vessel/${id}/fetchVesselParticulars`,
    },
    { apiName: this.apiName });

  fetchVesselParticularsByImoNumberAndMmsi = (imoNumber: string, mmsi: string) =>
    this.restService.request<any, VD02Response>({
      method: 'POST',
      url: `/api/app/vessel/fetchVesselParticulars`,
      params: { imoNumber: imoNumber, mmsi: mmsi },
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, VesselDto>({
      method: 'GET',
      url: `/api/app/vessel/${id}`,
    },
    { apiName: this.apiName });

  getCount = (input: PageRequestDto) =>
    this.restService.request<any, number>({
      method: 'GET',
      url: `/api/app/vessel/count`,
      params: { filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getDropDownOptions = (input: DataRequestDto) =>
    this.restService.request<any, PagedResultDto<IdNameDto>>({
      method: 'GET',
      url: `/api/app/vessel/dropDownOptions`,
      params: { select: input.select, filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getExpandById = (id: string) =>
    this.restService.request<any, VesselDto[]>({
      method: 'GET',
      url: `/api/app/vessel/${id}/expand`,
    },
    { apiName: this.apiName });

  getExtraPropertiesById = (id: string) =>
    this.restService.request<any, Record<string, object>>({
      method: 'GET',
      url: `/api/app/vessel/${id}/extraProperties`,
    },
    { apiName: this.apiName });

  getIds = (input: DataRequestDto) =>
    this.restService.request<any, string[]>({
      method: 'GET',
      url: `/api/app/vessel/ids`,
      params: { select: input.select, filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getList = (input: PageRequestDto) =>
    this.restService.request<any, PagedResultDto<VesselDto>>({
      method: 'GET',
      url: `/api/app/vessel`,
      params: { filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getListWithOutDetails = (input: PageRequestDto) =>
    this.restService.request<any, PagedResultDto<VesselListDto>>({
      method: 'GET',
      url: `/api/app/vessel/withOutDetails`,
      params: { filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getOneExtraPropertiesByIdAndKey = (id: string, key: string) =>
    this.restService.request<any, object>({
      method: 'GET',
      url: `/api/app/vessel/${id}/oneExtraProperties`,
      params: { key: key },
    },
    { apiName: this.apiName });

  getSchema = () =>
    this.restService.request<any, EntitySchemaDto>({
      method: 'GET',
      url: `/api/app/vessel/schema`,
    },
    { apiName: this.apiName });

  getWithDetails = (id: string) =>
    this.restService.request<any, VesselDto>({
      method: 'GET',
      url: `/api/app/vessel/${id}/withDetails`,
    },
    { apiName: this.apiName });

  setExtraPropertiesByIdAndKeyAndValue = (id: string, key: string, value: string) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/vessel/${id}/setExtraProperties`,
      params: { key: key, value: value },
    },
    { apiName: this.apiName });

  singleVesselParticularsByImoNumberAndMmsi = (imoNumber: string, mmsi: string) =>
    this.restService.request<any, PS07Response>({
      method: 'POST',
      url: `/api/app/vessel/singleVesselParticulars`,
      params: { imoNumber: imoNumber, mmsi: mmsi },
    },
    { apiName: this.apiName });

  update = (id: string, input: UpdateVesselDto) =>
    this.restService.request<any, VesselDto>({
      method: 'PUT',
      url: `/api/app/vessel/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
