import type { CreateVendorDto, UpdateVendorDto, VendorDto, VendorListDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { DataRequestDto, EntitySchemaDto, IdNameDto, PageRequestDto } from '../models';

@Injectable({
  providedIn: 'root',
})
export class VendorService {
  apiName = 'Default';

  create = (input: CreateVendorDto) =>
    this.restService.request<any, VendorDto>({
      method: 'POST',
      url: `/api/app/vendor`,
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/vendor/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, VendorDto>({
      method: 'GET',
      url: `/api/app/vendor/${id}`,
    },
    { apiName: this.apiName });

  getCount = (input: PageRequestDto) =>
    this.restService.request<any, number>({
      method: 'GET',
      url: `/api/app/vendor/count`,
      params: { filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getDropDownOptions = (input: DataRequestDto) =>
    this.restService.request<any, PagedResultDto<IdNameDto>>({
      method: 'GET',
      url: `/api/app/vendor/dropDownOptions`,
      params: { select: input.select, filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getExpandById = (id: string) =>
    this.restService.request<any, VendorDto[]>({
      method: 'GET',
      url: `/api/app/vendor/${id}/expand`,
    },
    { apiName: this.apiName });

  getExtraPropertiesById = (id: string) =>
    this.restService.request<any, Record<string, object>>({
      method: 'GET',
      url: `/api/app/vendor/${id}/extraProperties`,
    },
    { apiName: this.apiName });

  getIds = (input: DataRequestDto) =>
    this.restService.request<any, string[]>({
      method: 'GET',
      url: `/api/app/vendor/ids`,
      params: { select: input.select, filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getList = (input: PageRequestDto) =>
    this.restService.request<any, PagedResultDto<VendorDto>>({
      method: 'GET',
      url: `/api/app/vendor`,
      params: { filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getListWithOutDetails = (input: PageRequestDto) =>
    this.restService.request<any, PagedResultDto<VendorListDto>>({
      method: 'GET',
      url: `/api/app/vendor/withOutDetails`,
      params: { filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getOneExtraPropertiesByIdAndKey = (id: string, key: string) =>
    this.restService.request<any, object>({
      method: 'GET',
      url: `/api/app/vendor/${id}/oneExtraProperties`,
      params: { key: key },
    },
    { apiName: this.apiName });

  getSchema = () =>
    this.restService.request<any, EntitySchemaDto>({
      method: 'GET',
      url: `/api/app/vendor/schema`,
    },
    { apiName: this.apiName });

  getSupportMailByCountryCode = (countryCode: string) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: `/api/app/vendor/supportMail`,
      params: { countryCode: countryCode },
    },
    { apiName: this.apiName });

  getWithDetails = (id: string) =>
    this.restService.request<any, VendorDto>({
      method: 'GET',
      url: `/api/app/vendor/${id}/withDetails`,
    },
    { apiName: this.apiName });

  setExtraPropertiesByIdAndKeyAndValue = (id: string, key: string, value: string) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/vendor/${id}/setExtraProperties`,
      params: { key: key, value: value },
    },
    { apiName: this.apiName });

  update = (id: string, input: UpdateVendorDto) =>
    this.restService.request<any, VendorDto>({
      method: 'PUT',
      url: `/api/app/vendor/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
