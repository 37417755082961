import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { AttachmentDto, CreateAttachmentDto, UpdateAttachmentDto } from '../attachments/models';
import type { IFormFile } from '../microsoft/asp-net-core/http/models';
import type { DataRequestDto, EntitySchemaDto, IdNameDto, PageRequestDto } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  apiName = 'Default';

  copyAttachments = (fromEntityTypeFullName: string, fromEntityRecordId: string, toEntityTypeFullName: string, toEntityRecordId: string) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/attachment/copyAttachments`,
      params: { fromEntityTypeFullName: fromEntityTypeFullName, fromEntityRecordId: fromEntityRecordId, toEntityTypeFullName: toEntityTypeFullName, toEntityRecordId: toEntityRecordId },
    },
    { apiName: this.apiName });

  create = (input: CreateAttachmentDto) =>
    this.restService.request<any, AttachmentDto>({
      method: 'POST',
      url: `/api/app/attachment`,
      body: input,
    },
    { apiName: this.apiName });

  createOrReplace = (input: CreateAttachmentDto) =>
    this.restService.request<any, AttachmentDto>({
      method: 'POST',
      url: `/api/app/attachment/orReplace`,
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/attachment/${id}`,
    },
    { apiName: this.apiName });

  deleteByRecordId = (entityRecordId: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/attachment/byRecordId/${entityRecordId}`,
    },
    { apiName: this.apiName });

  download = (id: string, path: string) =>
    this.restService.request<any, AttachmentDto>({
      method: 'POST',
      url: `/api/app/attachment/${id}/download`,
      params: { path: path },
    },
    { apiName: this.apiName });

  downloadById = (id: string) =>
    this.restService.request<any, AttachmentDto>({
      method: 'POST',
      url: `/api/app/attachment/${id}/downloadById`,
    },
    { apiName: this.apiName });

  downloadByRecordId = (entityRecordId: string) =>
    this.restService.request<any, AttachmentDto>({
      method: 'POST',
      url: `/api/app/attachment/downloadByRecordId/${entityRecordId}`,
    },
    { apiName: this.apiName });

  downloadFile = (entity: string, fileName: string) =>
    this.restService.request<any, number[]>({
      method: 'POST',
      url: `/api/app/attachment/downloadFile`,
      params: { entity: entity, fileName: fileName },
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, AttachmentDto>({
      method: 'GET',
      url: `/api/app/attachment/${id}`,
    },
    { apiName: this.apiName });

  getByRecordId = (entityRecordId: string) =>
    this.restService.request<any, AttachmentDto>({
      method: 'GET',
      url: `/api/app/attachment/byRecordId/${entityRecordId}`,
    },
    { apiName: this.apiName });

  getCount = (input: PageRequestDto) =>
    this.restService.request<any, number>({
      method: 'GET',
      url: `/api/app/attachment/count`,
      params: { filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getDropDownOptions = (input: DataRequestDto) =>
    this.restService.request<any, PagedResultDto<IdNameDto>>({
      method: 'GET',
      url: `/api/app/attachment/dropDownOptions`,
      params: { select: input.select, filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getExpandById = (id: string) =>
    this.restService.request<any, AttachmentDto[]>({
      method: 'GET',
      url: `/api/app/attachment/${id}/expand`,
    },
    { apiName: this.apiName });

  getExtraPropertiesById = (id: string) =>
    this.restService.request<any, Record<string, object>>({
      method: 'GET',
      url: `/api/app/attachment/${id}/extraProperties`,
    },
    { apiName: this.apiName });

  getIds = (input: DataRequestDto) =>
    this.restService.request<any, string[]>({
      method: 'GET',
      url: `/api/app/attachment/ids`,
      params: { select: input.select, filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getList = (input: PageRequestDto) =>
    this.restService.request<any, PagedResultDto<AttachmentDto>>({
      method: 'GET',
      url: `/api/app/attachment`,
      params: { filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getOneExtraPropertiesByIdAndKey = (id: string, key: string) =>
    this.restService.request<any, object>({
      method: 'GET',
      url: `/api/app/attachment/${id}/oneExtraProperties`,
      params: { key: key },
    },
    { apiName: this.apiName });

  getSchema = () =>
    this.restService.request<any, EntitySchemaDto>({
      method: 'GET',
      url: `/api/app/attachment/schema`,
    },
    { apiName: this.apiName });

  getWithDetails = (id: string) =>
    this.restService.request<any, AttachmentDto>({
      method: 'GET',
      url: `/api/app/attachment/${id}/withDetails`,
    },
    { apiName: this.apiName });

  setExtraPropertiesByIdAndKeyAndValue = (id: string, key: string, value: string) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/attachment/${id}/setExtraProperties`,
      params: { key: key, value: value },
    },
    { apiName: this.apiName });

  update = (id: string, input: UpdateAttachmentDto) =>
    this.restService.request<any, AttachmentDto>({
      method: 'PUT',
      url: `/api/app/attachment/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  upload = (entity: string, fileName: string, file: IFormFile) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: `/api/app/attachment/upload`,
      params: { entity: entity, fileName: fileName },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
