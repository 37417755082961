import type { CreateInvoiceDto, InvoiceDto, UpdateInvoiceDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { InvoiceStatus } from '../invoices/invoice-status.enum';
import type { DataRequestDto, EntitySchemaDto, IdNameDto, PageRequestDto } from '../models';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  apiName = 'Default';

  create = (input: CreateInvoiceDto) =>
    this.restService.request<any, InvoiceDto>({
      method: 'POST',
      url: `/api/app/invoice`,
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/invoice/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, InvoiceDto>({
      method: 'GET',
      url: `/api/app/invoice/${id}`,
    },
    { apiName: this.apiName });

  getCount = (input: PageRequestDto) =>
    this.restService.request<any, number>({
      method: 'GET',
      url: `/api/app/invoice/count`,
      params: { filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getDropDownOptions = (input: DataRequestDto) =>
    this.restService.request<any, PagedResultDto<IdNameDto>>({
      method: 'GET',
      url: `/api/app/invoice/dropDownOptions`,
      params: { select: input.select, filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getExpandById = (id: string) =>
    this.restService.request<any, InvoiceDto[]>({
      method: 'GET',
      url: `/api/app/invoice/${id}/expand`,
    },
    { apiName: this.apiName });

  getExtraPropertiesById = (id: string) =>
    this.restService.request<any, Record<string, object>>({
      method: 'GET',
      url: `/api/app/invoice/${id}/extraProperties`,
    },
    { apiName: this.apiName });

  getIds = (input: DataRequestDto) =>
    this.restService.request<any, string[]>({
      method: 'GET',
      url: `/api/app/invoice/ids`,
      params: { select: input.select, filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getList = (input: PageRequestDto) =>
    this.restService.request<any, PagedResultDto<InvoiceDto>>({
      method: 'GET',
      url: `/api/app/invoice`,
      params: { filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getOneExtraPropertiesByIdAndKey = (id: string, key: string) =>
    this.restService.request<any, object>({
      method: 'GET',
      url: `/api/app/invoice/${id}/oneExtraProperties`,
      params: { key: key },
    },
    { apiName: this.apiName });

  getPettyCashToExcelByVendorIdAndFromDateAndToDate = (vendorId: string, fromDate: string, toDate: string) =>
    this.restService.request<any, number[]>({
      method: 'GET',
      url: `/api/app/invoice/pettyCashToExcel/${vendorId}`,
      params: { fromDate: fromDate, toDate: toDate },
    },
    { apiName: this.apiName });

  getProjectInvoice = (projectId: string) =>
    this.restService.request<any, InvoiceDto[]>({
      method: 'GET',
      url: `/api/app/invoice/projectInvoice/${projectId}`,
    },
    { apiName: this.apiName });

  getSchema = () =>
    this.restService.request<any, EntitySchemaDto>({
      method: 'GET',
      url: `/api/app/invoice/schema`,
    },
    { apiName: this.apiName });

  getWithDetails = (id: string) =>
    this.restService.request<any, InvoiceDto>({
      method: 'GET',
      url: `/api/app/invoice/${id}/withDetails`,
    },
    { apiName: this.apiName });

  sendEmailOnInvoiceRejectByInvoiceId = (invoiceId: string) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/invoice/sendEmailOnInvoiceReject/${invoiceId}`,
    },
    { apiName: this.apiName });

  sendEmailOnInvoiceUploadByInvoiceId = (invoiceId: string) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/invoice/sendEmailOnInvoiceUpload/${invoiceId}`,
    },
    { apiName: this.apiName });

  setExtraPropertiesByIdAndKeyAndValue = (id: string, key: string, value: string) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/invoice/${id}/setExtraProperties`,
      params: { key: key, value: value },
    },
    { apiName: this.apiName });

  update = (id: string, input: UpdateInvoiceDto) =>
    this.restService.request<any, InvoiceDto>({
      method: 'PUT',
      url: `/api/app/invoice/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  updateStatusChange = (id: string, status: InvoiceStatus, comments: string) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/invoice/${id}/statusChange`,
      params: { status: status, comments: comments },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
