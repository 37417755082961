import { mapEnumToOptions } from '@abp/ng.core';

export enum PortLocationType {
  Anchorage = 0,
  Terminal = 1,
  Container = 2,
  Shipyard = 3,
}

export const portLocationTypeOptions = mapEnumToOptions(PortLocationType);
